<template>
    <div>
        <fe-dialog
            v-if="comingSoon"
            @close="comingSoon = false"
            @dismiss="comingSoon = false"
            @accept="comingSoon = false"
            title="Coming Soon"
            body="This feature will be available soon."
        />
        <v-dialog
            v-if="details.show"
            v-model="modalActive"
            :title="details.title"
            width="80%"
            :transition="false"
            :retain-focus="false"
        >
            <v-card color="white" style="width: 100%; height: 500px;" class="d-flex flex-column">
                <v-card-title class="fe-dialog-card-title">
                    {{ details.title }}
                    <v-spacer></v-spacer>
                    <v-btn icon @click="details.show=false">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text class="fe-dialog-card-text-no-padding flex-grow-1 flex-fill flex-column" style="overflow: hidden;">
                    <component ref="comp" :is="details.component" :params="details.params" v-bind="details.attrs" v-on="details.listeners"/>
                </v-card-text>
            </v-card>
        </v-dialog>
        <div class="d-flex">
            <div class="d-flex mr-2 flex-grow-1">
                <div
                    v-for="(att, i) in enabledAttendanceTypes"
                    :key="`count-att-type-idx-${i}`"
                    class="counter-btn flex-grow-1"
                    :class="[{first: i==0 }, {middle: i != 0 && i != enabledAttendanceTypes.length-1 }, { last: i==enabledAttendanceTypes.length-1 }]"
                    :style="[{'background-color': att.color }]"
                    :data-test=att.dataTest
                    @click="att.handler"
                >
                    <fe-tooltip :tooltip="`${att.tooltip}`">
                        <div class="number-display">{{ att.value }}</div>
                    </fe-tooltip>

                    <div class="title-display">
                        <v-icon class="title-icon" v-if="att.icon">{{att.icon}}</v-icon>
                        <span class="ml-2" v-if="$vuetify.breakpoint.lgAndUp">{{att.name}}</span>
                    </div>
                </div>
            </div>

            <div v-for="(section, idx) in sections" class="mr-2 flex-grow-1" :key="`stdnt-sectn-idx-${idx}`" :data-test=section.dataTest>
                <div class="counter-btn" :style="[{'background-color': section.color }]" @click="section.handler">
                    <fe-tooltip :tooltip="`${section.name} - ${section.value}`">
                        <div class="number-display">{{ section.value }}</div>
                    </fe-tooltip>
                    <div class="title-display">
                        <v-icon class="title-icon" v-if="section.icon">{{section.icon}}</v-icon>
                        <span class="ml-2" v-if="$vuetify.breakpoint.lgAndUp">{{section.name}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import StudentTagList from './TagList'
    import Message from '@/components/common/Message'
    import FormList from '@/components/modules/smartforms/List'
    import ObservationList from '@/components/modules/observations/List'
    import ThresholdsTable from './ThresholdsTable'
    import { mapState } from 'vuex'
    import { bus } from '@/main'

    export default {
        name: 'CountBar',
        props: {
            studentRec: {
                required: true
            }
        },
        created (){
            bus.$on('refreshCount', () => {
                this.loadData()
            })
        },
        computed: {
            ...mapState('global', ['districtConfig']),
            ldObservationsFlagEnabled: me => me.$store.getters['flags/flags']['ec-observations'] === true,
            enabledAttendanceTypes() {
                let fullDayAtt = this.attendance.find(att => att.name == "Full Day Attendance")
                let sisAtt = this.attendance.find(att => att.name == "SIS Reported Attendance")

                let possibleDays = this.attendance[0].rec?.possible_days
                let affectedDays = this.attendance[0].rec?.affected_days

                fullDayAtt.tooltip = `${this.studentInfo.fname} had ${possibleDays - affectedDays} out of ${possibleDays} possible days without an attendance event.`
                sisAtt.tooltip = `${this.studentInfo.fname} attended ${possibleDays - affectedDays} out of ${possibleDays} possible school days.`

                if (!this.districtConfig) {
                    return this.attendance;
                }
                return this.attendance.filter(rec => {
                    return this.districtConfig[rec.show] })
            },
            modalActive: {
                get () { return this.details.show },
                set (v) { this.details.show = v }
            }
        },
        components: {
            StudentTagList,
            Message,
            FormList,
            ObservationList,
            ThresholdsTable,
        },
        data() {
            let me = this
            return {
                comingSoon: false,
                details: {
                    show: false,
                    title: 'Not Set',
                    records: [],
                    attrs: {}
                },
                studentInfo: {},
                sections: [{
                    name: 'Forms',
                    value: 0,
                    color: '#AE5DFF',
                    icon: 'fas fa-clipboard-list',
                    dataTest: 'student-profile-smartforms',
                    handler() {
                        let d = me.$data.details
                        d.title = "Student smartFORMS"
                        d.component = 'form-list'
                        d.params    = {
                            student_id: me.$props.studentRec?.student_id,
                            property: 'student_instance'
                        }
                        d.attrs = {
                            style: {
                                height: '100%'
                            },
                            domLayout: "normal",
                            folderCreate: false,
                            studentId: me.$props.studentRec?.student_id,
                        }

                        d.listeners = {
                            closeDialog: () => { d.show = true },
                            toggleDialog: (visible) => { d.show = !visible },
                            openItem: () => { d.show = false }
                        }
                        me.$data.details.show = true
                    }
                }, {
                    name: 'Comments',
                    value: 0,
                    color: '#2B87FF',
                    icon: 'fas fa-comments',
                    dataTest: 'student-profile-comments',
                    handler: () => {
                        let student = this.studentRec
                        let d = this.details
                        d.title = "Comments",
                        d.component = 'message'
                        d.params    = {
                            student_id: student.student_id
                        }

                        this.details.show = true
                    }
                }, {
                    name: 'Tags',
                    value: 0,
                    color: '#FF675D',
                    icon: 'fas fa-tags',
                    dataTest: 'student-profile-tags',
                    handler() {
                        let d = me.$data.details
                        d.title = "Student Tags"
                        d.component = 'student-tag-list'
                        d.params    = {
                            student_id: me.$props.studentRec?.student_id
                        }
                        d.attrs = {
                            style: {
                                height: '100%'
                            },
                            domLayout: "normal"
                        }

                        d.listeners = {
                            'addTagClick': (stdnt, ref) => {
                                me.$store.commit('global/tagStudents', {
                                    show: true,
                                    students: [{
                                        student_id: me.$props.studentRec?.student_id
                                    }],
                                    callback() {
                                        if (me.$refs.comp && me.$refs.comp.reload) me.$refs.comp.reload()
                                    }
                                })
                            }
                        }
                        me.$data.details.show = true
                    }
                }, {
                    name: 'Thresholds',
                    value: 0,
                    color: '#746DE0',
                    icon: 'fas fa-flag-checkered',
                    dataTest: 'student-profile-thresholds',
                    handler() {
                        let d = me.$data.details
                        d.title = "Student Thresholds"
                        d.component = 'thresholds-table'
                        d.params = {
                            student_id: me.$props.studentRec?.student_id,
                            school_year_id: me.$props.studentRec?.school_year_id,
                        }
                        d.attrs = {
                            style: {
                                height: '100%'
                            },
                            domLayout: "normal"
                        }
                        me.$data.details.show = true
                    }
                }, {
                    name: 'Observations',
                    value: 0,
                    color: '#FF8E07',
                    icon: 'fas fa-street-view',
                    dataTest: 'student-profile-observations',
                    handler() {
                        if (!me.ldObservationsFlagEnabled) {
                            me.comingSoon = true
                            return
                        }

                        let d = me.$data.details
                        d.title = `${me.studentRec.student_first_name || 'Student'}'s Observations`
                        d.component = 'observation-list'
                        d.params    = {
                            student_id: me.$props.studentRec?.student_id,
                            studentId: me.$props.studentRec?.student_id,
                        }
                        d.attrs = {
                            style: {
                                height: '100%'
                            },
                            domLayout: "normal",
                            studentId: me.$props.studentRec?.student_id,
                            studentFirstName: me.studentRec?.student_first_name,
                            schoolYearId: me.studentRec?.school_year_id,
                            persistent: true,
                        }
                        d.listeners = {
                            closeDialog: () => { d.show = false },
                            toggleDialog: (visible) => { d.show = !visible },
                            openItem: () => { d.show = false },
                            refresh: () => { me.loadData() },
                        }
                        me.$data.details.show = true
                    }
                }],
                attendance: [{
                    name: 'Full Day Attendance',
                    value: 0,
                    show: 'ATTENDANCE_AFFECTED_RATE',
                    color: '#0049FF',
                    icon: 'fas fa-theater-masks',
                    dataTest: 'student-profile-full-day-attendance',
                    handler() {
                        me.$store.commit('global/addDockableWindow', {
                            name: me.studentInfo.fname + ' ' + me.studentInfo.lname + ' Attendance',
                            component: 'student-attendance',
                            attrs: {
                                params: {
                                    student_id: me.$props.studentRec?.student_id,
                                    school_year_id: me.$props.studentRec?.school_year_id
                                },
                                pinnable: false
                            },
                            events: {
                                toggle: () => { me.update() },
                                close: () => { me.update() }
                            }
                        })
                    },
                    tooltip: ''
                }, {
                    name: 'SIS Reported Attendance',
                    value: 0,
                    show: 'ATTENDANCE_EQUIV_RATE',
                    color: '#0EA449',
                    icon: 'fas fa-clipboard-list',
                    dataTest: 'student-profile-SIS-attendance',
                    handler() {
                        me.$store.commit('global/addDockableWindow', {
                            name: me.studentInfo.fname + ' ' + me.studentInfo.lname + ' Attendance',
                            component: 'student-attendance',
                            attrs: {
                                params: {
                                    student_id: me.$props.studentRec?.student_id,
                                    school_year_id: me.$props.studentRec?.school_year_id
                                },
                                pinnable: false
                            },
                            events: {
                                toggle: () => { me.update() },
                                close: () => { me.update() }
                            }
                        })
                    },
                    tooltip: ''
                }, {
                    name: 'Period Attendance',
                    show: 'ATTENDANCE_PERIOD_RATE',
                    value: 0,
                    color: '#803CA9',
                    icon: 'fas fa-clipboard-list',
                    dataTest: 'student-profile-period-attendance',
                    handler() {}
                }]
            }
        },
        watch: {
            'details.show' (v) { if (!v) this.update() },
            studentRec: {
                deep: true,
                handler(v) {
                    if (v?.student_id) {
                        this.loadData()
                    }
                }
            }
        },
        mounted() {
            if (this.$props.studentRec?.student_id) {
                this.loadData()
            }
        },
        methods: {
            update () { this.loadData() },
            showAttendance(tp) {
                return this.districtConfig[tp]
            },
            loadData() {
                let params = this.$objectToParams({
                    // ...this.studentRec
                    // pass school_id as undefined
                    // including a value can cause an issue when initially loading counts on profile page
                    // it is undefined when accessing a student profile from student search and counts always load
                    school_id:          undefined,
                    student_id:         this.studentRec.student_id,
                    school_year_id:     this.studentRec.school_year_id,
                    student_full_name:  this.studentRec.student_full_name,
                    student_first_name: this.studentRec.student_first_name
                })

                this.$axios.get('/api/core/student/profile/overview?' + params).then(res => {
                    let v = 0

                    // Update overall student info
                    this.studentInfo = res.data?.overview?.studentData[0]

                    // Update forms count
                    v = res.data?.overview?.formCounts?.totalCount || 0
                    this.sections.find(s => s.name == 'Forms').value = v

                    // Update tags count
                    v = res.data?.overview?.tags?.length || 0
                    this.sections.find(s => s.name == 'Tags').value = v

                    // Update message count
                    v = res.data?.overview?.messageCounts?.totalCount || 0
                    this.sections.find(s => s.name == 'Comments').value = v

                    // Update threshold count
                    v = res.data?.overview?.thresholdGroupResults?.length || 0
                    this.sections.find(s => s.name == 'Thresholds').value = v

                    // Update observations count
                    v = res.data?.overview?.observationCounts?.totalCount || 0
                    this.sections.find(s => s.name == 'Observations').value = v

                    this.buildAttendance(res.data?.overview?.attendanceCharts)

                    this.$emit('elpLevel', res.data?.overview?.studentData[0].ell_level_name)
                })
            },
            buildAttendance(charts) {
                let me = this

                // If no attendance data exists or is allowed to be viewed (i.e.
                // user might not have view access to this student beyond a certain
                // school year), then reset all attendance values to 0 and exit
                if (charts?.filter(itm => !!itm.data?.length).length === 0) {
                    this.attendance = this.attendance.map(itm => ({
                        ...itm,
                        value: 0,
                    }))

                    return
                }

                charts?.forEach(chart => {
                    chart.data.forEach(data => {
                        chart.y_fields.forEach(field => {
                            let rate = data[field]
                            let display = me.$data.attendance.find(att => {
                                let f = field.split('Rate')[0].replace(/\s/g, '');
                                let r = att.name.replace(/\s/g, '');
                                return r === f
                            })
                            if (display) {
                                display.value = rate
                                display.rec   = data
                            }
                        })
                    })
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.detail-container {
    height: 400px;
    overflow: scroll;
    padding: 16px;

    &-title {
        font-weight: bold;
    }

    &-description {
        padding-left: 24px;
        color: gray;
        font-size: 12px;
    }
}

.counter-btn {
    border-radius: 5px;
    height: 56px;
    cursor: pointer;

    .number-display {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        text-align: center;
        color: white;
        padding-top: 10px;
    }

    .title-display {
        font-size: 10px;
        color: white;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin: 0px auto 0px auto;
        max-width: 80px;
        padding: 0px 5px 0px 5px;

        .title-icon {
            font-size: 10px;
            color: white;
        }
    }

    &.first {
        border-radius: unset;
        border-top-left-radius: 5px !important;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        border-bottom-left-radius: 5px !important;
    }

    &.last {
        border-radius: unset;
        border-top-left-radius: 0px !important;
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
        border-bottom-left-radius: 0px !important;
    }

    &.middle {
        border-radius: 0;
    }
}
</style>
